@import 'variable';

#root {
  height: 100vh;
  font-family: 'Poppins', sans-serif;
}

//=========================================
/********** margin padding function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 8, 16, 24, 32, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }
  }
}

.make-space();

//=========================================
/********** margin padding function end here **************/
//=========================================
.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen767 {
    display: block;
  }
}

.wrapper {
  padding: 10px;
  padding-bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media @screen991 {
    font-size: 14px;
    padding: 10px;
  }

  @media @screen767 {
    padding: 10px;
    padding-bottom: 0;
  }

  @media only screen and (max-width: 600px) {
    padding: 8px;
  }
}

.responsive-table {
  width: 100%;
  overflow: auto;
}

.ant-table {
  .ant-image-mask {
    display: none;
  }

  .filter-icon {
    font-size: 15px;
    &.anticon {
      color: @primary-color;
    }
  }

  .filter-filled {
    color: @primary-color;
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
      height: auto;
    }
  }
}

.full-height-without-nav .nested-table,
.tab-table .nested-table {
  .ant-table-placeholder {
    height: auto;
  }
  .ant-table-content {
    overflow: visible !important;

    table {
      font-size: 12px;
    }
  }
  padding: 16px 0px;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}
//=========================================
/********** text alignment end here **************/
//=========================================

//=========================================
/********** flex box class start here **************/
//=========================================

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

//=========================================
/********** flex box class end here **************/
//=========================================

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-height-without-nav {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgb(64 112 118 / 18%);

  .ant-card-body {
    height: calc(100vh - 85px);
    overflow-y: auto;
  }

  .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 300px);
  }

  .ant-table-placeholder {
    height: calc(100vh - 310px);
  }

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 125px);

    .ant-card-body {
      height: calc(100vh - 125px);
      overflow-y: auto;
    }

    .ant-table-body {
      max-height: none !important;
      height: calc(100vh - 351px);
    }

    .ant-table-placeholder {
      height: calc(100vh - 357px);
    }
  }
}

.full-height-without-nav-vertical-tabs {
  height: calc(100vh - 260px);

  .ant-card-body {
    height: calc(100vh - 260px);
    overflow-y: auto;
  }

  .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 490px);
  }

  .ant-table-placeholder {
    height: calc(100vh - 500px);
  }

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 315px);

    .ant-card-body {
      height: calc(100vh - 315px);
      overflow-y: auto;
    }

    .ant-table-body {
      max-height: none !important;
      height: calc(100vh - 541px);
    }

    .ant-table-placeholder {
      height: calc(100vh - 547px);
    }
  }
}

.full-height-with-nav {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgb(64 112 118 / 18%);

  .ant-card-body {
    height: calc(100vh - 131px);
    overflow-y: auto;
  }

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 125px);

    .ant-card-body {
      height: calc(100vh - 172px);
    }
  }
}

.full-height-without-details {
  border-radius: 0px;

  .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 390px);
  }

  .ant-table-placeholder {
    height: calc(100vh - 396px);
  }

  @media only screen and (max-width: 600px) {
    .ant-table-body {
      max-height: none !important;
      height: calc(100vh - 455px);
    }

    .ant-table-placeholder {
      height: calc(100vh - 461px);
    }
  }
}

.tab-table {
  border-radius: 0px;

  .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 341px);
  }

  .ant-table-placeholder {
    height: calc(100vh - 347px);
  }

  @media only screen and (max-width: 600px) {
    .ant-table-body {
      max-height: none !important;
      height: calc(100vh - 406px);
    }

    .ant-table-placeholder {
      height: calc(100vh - 412px);
    }
  }
}

//width classes

.site-result-demo-error-icon {
  color: @colorError;
}

//border style
.b-0 {
  border: none;
}

//border style

/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/*position class end here*/

//CUSTOM STYLE CLASSES END

.header-left-content {
  .d-flex;
  .justify-between;
  .align-center;
}

.sticky-action-form {
  .ant-card-head-title {
    @media @screen767 {
      padding: 0;
    }
  }

  .ant-page-header-heading {
    @media @screen767 {
      padding: 0;
      min-height: 48px;
    }
  }
}

#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji,
    segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  .d-flex;
  .align-center;
  .justify-center;
  color: #121d45;
}

.delete-icon {
  color: rgb(233, 36, 36);
  cursor: pointer;
}

/*table draggable css start here*/
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/*sidebar custom less start here*/

.responsive-logo img {
  width: 44px;
}

.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;
}

.ant-layout-sider.ant-layout-sider-light {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 80px !important;
  width: 230px !important;
}

.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed {
  width: 80px !important;
}

.ant-layout-sider {
  // border-right: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 30px rgb(64 112 118 / 18%);

  @media @screen767 {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -240px;
    z-index: 12;
  }
}

.ant-layout.ant-layout-has-sider {
  height: 100%;
}

.ant-layout-header {
  @media @screen767 {
    padding: 0 16px !important;
  }
}

header.ant-layout-header.site-layout-sub-header-background.app-header {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 15px;
  color: @white-color;
  line-height: 64px;
  background: #407076;
  box-shadow: 0 12px 30px rgb(64 112 118 / 18%);
}

.overlay-disable {
  @media @screen767 {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.6;
    z-index: 11;
  }
}

/*sidebar custom less end here*/
.ant-layout-footer {
  @media @screen767 {
    padding: 16px 50px;
  }
}

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
  background-color: #aaa;
}

:hover::-webkit-scrollbar-thumb {
  // border-left: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

::-webkit-selection {
  background: #cedada !important;
}

::selection {
  background: #cedada !important;
}

::-moz-selection {
  background: #cedada !important;
}

/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;

  .ant-card-head {
    position: relative;
    z-index: 1;
  }

  .ant-page-header-heading {
    min-height: 48px;
  }

  > .ant-card-body {
    flex: 1 1;
    height: 100%;
    padding: 15px;
    overflow: hidden;

    .card-body-wrapper {
      height: 100%;
      overflow: auto;
      padding: 24px;
    }
  }
}

.ant-card-small {
  > .ant-card-body {
    .card-body-wrapper {
      padding: 12px;
    }
  }
}

:where(.css-dev-only-do-not-override-1oigu4d).ant-typography ul li,
:where(.css-dev-only-do-not-override-1oigu4d).ant-typography ol li {
  margin-inline: 0px;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0px;
}

.ant-card .ant-card-head {
  min-height: 50px;
}

/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
}

/*fleg dropdown css end here*/

/*sidebar css start here*/
.sidebar-content {
  overflow-y: auto;
  .ant-menu-item-icon {
    background: @white-color;
    width: 30px;
    height: 30px;
    line-height: 32px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgb(83 108 167 / 16%);
    display: flex;
    justify-content: center;
  }
  .ant-menu-inline-collapsed span.ant-badge.ant-menu-item-icon {
    margin-left: 5px;
    margin-top: 5px;
    .anticon {
      margin: 0px;
      background: @white-color;
      width: 30px;
      height: 30px;
      line-height: 32px;
      border-radius: 4px;
      box-shadow: 0 3px 6px rgb(83 108 167 / 16%);
      display: flex;
      justify-content: center;
    }
  }
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-inline: 20px;
  }
  li.ant-menu-item.ant-menu-item-only-child {
    padding-left: 55px !important;
  }
  .ant-menu-submenu-open {
    background: #f4f5fa;
  }
  .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon {
    margin-left: 5px;
    margin-top: 5px;
  }
  .ant-menu-inline-collapsed.ant-menu-light
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title {
    background: #aab5b552;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-submenu-title {
    width: auto;
    padding-left: 15px !important;
  }
  .ant-menu-inline-collapsed .ant-menu-submenu-open {
    background: none;
  }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: none;
  }
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    height: calc(100vh - 230px);
    padding: 10px 5px;
    border-bottom: 2px solid #f4f5fa;
    overflow: auto;
  }
  .ant-menu.ant-menu-inline-collapsed {
    height: calc(100vh - 350px);
    border-bottom: 2px solid #f4f5fa;

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: calc(50% - 25px) !important;
    }
  }
  .ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    padding: 10px 0px;
  }
  li.ant-menu-submenu.ant-menu-submenu-inline {
    padding-top: 1px;
  }
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    border: 0px;
  }
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    border: 0px;
  }
  .ant-menu-item-selected {
    background-color: #aab5b552;
  }
}

.sidebar-bottom-content {
  padding: 20px 0px;
  border-top: 1px solid #e8e8e8;
  font-size: 14px;
  max-height: 80px;

  .ant-menu-item .ant-menu-item-icon {
    background: @white-color;
    margin-top: 5px;
    width: 30px;
    height: 30px;
    line-height: 32px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgb(83 108 167 / 16%);
    display: flex;
    justify-content: center;
  }
  .ant-menu-inline-collapsed > .ant-menu-item {
    padding-inline: 20px;
  }
  li.ant-menu-item {
    margin-top: 10px;
  }
  .user-name {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }
  }
  .logout-btn:hover {
    text-decoration: underline;
  }
  .ant-menu-item-selected {
    background-color: #aab5b552;
  }
}

/*sidebar css end here*/

.portal-header {
  text-transform: capitalize;
  font-size: 20px;
  color: @white-color;
  font-weight: 500;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media screen and (max-width: 920px) {
    max-width: 200px;
  }
  @media @screen540 {
    max-width: 75vw;
  }
  @media screen and (min-width: 921px) and (max-width: 1024px) {
    max-width: 430px;
  }
}

.common-button {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0 3px 11px rgb(0 0 0 / 17%);
  border: 1px solid @white-color;

  &:hover {
    border-color: @white-color !important;
  }
  .anticon {
    font-size: 12px;
  }
}

.table-common-btn {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  margin-right: 8px;
  box-shadow: none;
}

#header-title-content {
  display: flex;
}

#header-right-content {
  @media @screen540 {
    display: none;
  }
}

.back-button-wrapper {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

#header-right-content-phones {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  @media only screen and (min-width: 541px) {
    display: none;
  }
}

.menu-collapsed-unfold {
  height: 64px;
}

.card-container {
  .card-title {
    margin-left: 6px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    color: @primary-color;
    height: 28px;
    font-weight: 500;
    background: @tab-background-color;
    box-shadow: 0 12px 30px rgb(64 112 118 / 18%);
  }
  .ant-tabs-nav-list {
    box-shadow: 0 12px 30px rgb(64 112 118 / 18%);
  }
  .ant-tabs-card .ant-tabs-content {
    height: calc(100vh - 114px);
    border-radius: 0px;
    box-shadow: 0 12px 30px rgb(64 112 118 / 18%);
    background-color: @white-color;
    overflow-y: auto;
    margin-top: -16px;

    @media only screen and (max-width: 600px) {
      height: calc(100vh - 160px);
    }
  }
  .ant-form-item-explain {
    margin: 3px 0 9px 0px;
  }
  .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 24px;
    background: @white-color;
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
    background: @white-color !important;
    border-color: @white-color;
  }
  [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
}

.nested-tabs {
  .ant-tabs-tab {
    color: @primary-color;
    height: 40px;
    font-weight: 500;
    background: none !important;
  }
  .ant-tabs-nav-list {
    box-shadow: 0 12px 30px rgb(64 112 118 / 18%);
  }
  .ant-tabs-content.ant-tabs-content-top {
    height: calc(100vh - 210px);
    border-radius: 0px;
    box-shadow: none;
    background-color: @white-color;
    font-size: 12px;
    overflow-y: auto;
    margin-top: -16px;

    @media only screen and (max-width: 600px) {
      height: calc(100vh - 256px);
    }
  }
  .ant-form-item-explain {
    margin: 3px 0 9px 0px;
  }
  .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 24px;
    background: @white-color;
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
    background: @white-color !important;
    border-color: @white-color;
  }
  [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
}

.ant-pagination {
  .ant-pagination-item-active {
    background-color: @primary-color;
  }
  .ant-pagination-item-active a {
    color: @white-color;
  }
  .ant-pagination-item-active a:hover {
    color: @white-color;
  }
}

.search-box {
  width: 20%;
  min-width: 150px;
  height: 32px;
}

.ant-card .ant-card-head {
  min-height: 46px;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  visibility: visible;
}

.max-width-column {
  max-width: 400px;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-btn-primary:disabled,
.ant-btn-default:disabled {
  color: grey;
  background-color: @white-color;
}

.ant-modal .ant-modal-header {
  margin-bottom: 24px;
}

.svg-icon {
  fill: @primary-color;
  height: 16px;
}

.svg-icon-grey {
  fill: grey;
  height: 16px;
}

.text-grey {
  color: grey;
}

.custom-filter-dropdown {
  padding: 12px;
  width: 254px;
  word-wrap: break-word;

  .range-input {
    margin-top: 10px;
  }
  .filter-search {
    width: 100%;
    margin-bottom: 12px;
  }
  .divider-filter {
    margin: 10px 0px;
  }

  .ok-button {
    width: 69px;
  }
  .reset-button {
    width: 69px;
    margin-right: 9px;
    border-radius: 6px;
    background-color: #f1f3f7;
    border: none;
  }
  .filter-checkboxes {
    margin-top: 10px;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    .single-checkbox-div {
      margin-bottom: 9px;
      .single-checkbox span {
        display: flex;
        span {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 170px;
        }
      }
      .single-checkbox {
        height: 32px;
        .ant-checkbox + span {
          padding: 0 9px;
          align-self: center;
        }
        .ant-checkbox-inner::after {
          left: 27%;
        }
        .ant-checkbox-inner {
          height: 24px;
          width: 24px;
        }
        .check-info {
          margin-left: 6px;
          vertical-align: bottom;
        }
      }
    }
  }
  .selected-divider {
    font-size: 13px;
    margin: 0px;
  }
  .filter-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filter-tag {
      margin: 4px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      span:first-child {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 10px;
    margin: 3px 0 9px 9px;
  }

  .ant-radio-wrapper {
    margin: 5px 0px;
  }
}

.filter-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  .filter-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 5px 5px 0px 0px;
    span {
      &:first-child {
        display: block;
        text-overflow: ellipsis;
        width: auto;
        max-width: 180px;
        overflow: hidden;
      }
    }
  }
}

.font-size-16 {
  font-size: 16px;
}

.table-action-primary-btn {
  font-size: 12px !important;
}

.ant-input-clear-icon {
  display: flex;
}

.common-view-form {
  .ant-select-selector {
    color: @text-color-secondary !important;
    background-color: @white-color !important;
  }
  .ant-input[disabled] {
    color: @text-color-secondary;
  }
  .ant-radio-wrapper-disabled {
    color: @text-color-secondary;
  }
  .ant-picker.ant-picker-disabled {
    background-color: @white-color;
    .ant-picker-input > input[disabled] {
      color: @text-color-secondary;
    }
  }
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
  width: auto;
}

.ant-image-img {
  border-radius: 50%;
}

.d-none {
  display: none;
}

.text-capitalize {
  text-transform: capitalize;
}

.preview-modal {
  .ant-modal.modal .ant-modal-body {
    max-height: 800px;
  }
  .center-image {
    display: flex;
    justify-content: center;
    .preview-image {
      width: 100%;
      max-width: 550px;
    }
  }
}

.ant-picker-input input {
  font-family: 'Poppins', sans-serif;
}

.action-button {
  display: flex;
  align-items: center;
}

.ant-tooltip {
  --antd-arrow-background-color: @tooltip-color;

  .ant-tooltip-inner {
    background: @tooltip-color;
    color: @black-color;
    box-shadow: none;
  }
}

.ant-modal-footer {
  padding-top: 20px;
}

.table-data-with-id {
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-size-12 {
  font-size: 12px;
}

.table-action-button .ant-btn-link:disabled,
.table-action-button {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.ant-table .ant-table-tbody > tr > td {
  padding: 0px 16px;
  height: 55px;
}

.table-cell:hover {
  color: @hover-color;
}

.table-tooltip-cell:hover {
  color: @table-cell-tooltip-hover;
}

.cursor-auto {
  cursor: auto;
}

.picker-with-scroll .ant-picker-panel-container {
  overflow: scroll;
  max-width: 100vw;
}

.ant-color-picker-trigger {
  height: auto;
}

.nested-tab-inside-tab.ant-tabs {
  .ant-tabs-content-holder .ant-tabs-content {
    box-shadow: none;
    height: calc(100vh - 146px);
    overflow-y: hidden;

    .ant-tabs-tabpane {
      padding: 20px 24px 24px 48px;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    background: none;
    box-shadow: none;
    padding: 8px 24px 8px 0;
  }

  .tab-card-container {
    .ant-tabs-content-holder .ant-tabs-content {
      height: calc(100vh - 280px);
      overflow-y: auto;
      border-radius: 3px 8px 8px 8px;
      border: 1px solid @border-color;

      .ant-tabs-tabpane {
        padding: 24px;
      }
    }

    .ant-tabs-tab {
      color: @primary-color;
      height: 28px;
      font-weight: 500;
      background: @tab-background-color;
      padding: 8px 16px;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      background: @tab-background-dark-green-color !important;
    }

    .tab-table {
      border-radius: 0px;

      .ant-table-body {
        max-height: none !important;
        height: calc(100vh - 500px);
      }

      .ant-table-placeholder {
        height: calc(100vh - 506px);
      }

      @media only screen and (max-width: 600px) {
        .ant-table-body {
          max-height: none !important;
          height: calc(100vh - 565px);
        }

        .ant-table-placeholder {
          height: calc(100vh - 571px);
        }
      }
    }

    .tab-table .nested-table {
      .ant-table-placeholder {
        height: auto;
      }
      .ant-table-content {
        overflow: visible !important;

        table {
          font-size: 12px;
        }
      }
      padding: 16px 0px;
    }
  }
}
